<template>
  <div>
    <div class="mb-3">
      <label for="username" class="form-label">
        Nom d'utilisateur
        <small v-if="usernameEditable" class="text-info">
          (dans le cas où le nom d'utilisateur existe, des caractères aléatoires seront rajoutés en fin de nom d'utilisateur)
        </small>
      </label>
      <input type="email" class="form-control" v-model="userCopy.username" id="username" :readonly="!usernameEditable">
    </div>
    <div>
      <div class="mb-3">
        <label for="password" class="form-label">{{ $route.name !== "user_new" ? "Nouveau mot de passe" : "Mot de passe" }}</label>
        <input type="password" class="form-control" :class="{ 'border-danger': userCopy.password !== userCopy.passwordConfirm }" v-model="userCopy.password" id="password" autocomplete="new-password">
      </div>
      <div class="mb-3">
        <label for="password_confirm" class="form-label">
          Confirmation mot de passe
          <small v-if="userCopy.password !== userCopy.passwordConfirm" class="text-danger">
            - confirmation et mot de passe doivent être identique
          </small>
        </label>
        <input type="password" class="form-control" :class="{ 'border-danger': userCopy.password !== userCopy.passwordConfirm }" v-model="userCopy.passwordConfirm" id="password_confirm">
      </div>
    </div>
    <div class="mb-3" v-if="showRolesSelection">
      <label class="form-label" v-if="Object.keys(rolesList).length > 0">Droits</label>
      <div class="form-check form-switch" v-for="(humanRoleName, role) in rolesList" :key="'role-' + role">
        <input class="form-check-input" type="checkbox" role="switch" :id="'switch_role_' + role" :checked="userCopy.roles.includes(role)" @click="updateUserRole(role)">
        <label class="form-check-label" :for="'switch_role_' + role">{{ humanRoleName }}</label>
      </div>
    </div>
    <div class="mb-3">
      <label for="firstName" class="form-label">Prénom</label>
      <input type="text" class="form-control" :class="{ 'border-warning': userCopy.userInfos.firstName !== user.userInfos.firstName }" v-model="userCopy.userInfos.firstName" id="firstName">
    </div>
    <div class="mb-3">
      <label for="lastName" class="form-label">Nom</label>
      <input type="text" class="form-control" v-model="userCopy.userInfos.lastName" :class="{ 'border-warning': userCopy.userInfos.lastName !== user.userInfos.lastName }" id="lastName">
    </div>
    <div class="mb-3">
      <label class="form-label">
        Emails
        <button class="btn btn-sm btn-primary ms-3" @click="userCopy.userInfos.emails.push({ value: '', isVerified: false, isNew: true })">
          Ajouter
        </button>
      </label>
      <div v-for="(email, i) in userCopy.userInfos.emails" :key="'email-' + i" class="input-group mb-3">
        <input type="text" class="form-control" :class="{ 'border-warning': !user.userInfos.emails[i] || email.value !== user.userInfos.emails[i].value }" placeholder="email" v-model="email.value">
        <button class="btn btn-outline-info" type="button" v-if="!email.isVerified && !email.isNew">
          Email non vérifié
        </button>
        <button class="btn btn-outline-danger" type="button" @click="userCopy.userInfos.emails.splice(i, 1)">
          Supprimer
        </button>
      </div>
    </div>
    <div class="mb-3">
      <label class="form-label">
        Numéros de téléphone
        <button class="btn btn-sm btn-primary ms-3" @click="userCopy.userInfos.phoneNumbers.push({ value: '', isNew: true })">
          Ajouter
        </button>
      </label>
      <div v-for="(phoneNumber, i) in userCopy.userInfos.phoneNumbers" :key="'phoneNumber-' + i" class="input-group mb-3">
        <input type="text" class="form-control" :class="{ 'border-warning': !user.userInfos.phoneNumbers[i] || phoneNumber.value !== user.userInfos.phoneNumbers[i].value }" placeholder="numéro de téléphone"
          v-model="phoneNumber.value">
        <button class="btn btn-outline-danger" type="button" @click="userCopy.userInfos.phoneNumbers.splice(i, 1)">
          Supprimer
        </button>
      </div>
    </div>
    <div v-if="userHasDataChanged" class="text-end mt-5">
      <div class="btn-group" role="group">
        <button v-if="!userRecordInProgress" class="btn btn-primary" @click="userCopy = Object.assign({}, JSON.parse(JSON.stringify(user)))">
          {{ cancelText }}
        </button>
        <button class="btn btn-warning" @click="userRecord">
          {{ recordText }}
          <div v-if="userRecordInProgress" class="uk-margin-left uk-inline" uk-spinner="ratio:0.75"></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "User",
  props: {
    user: {
      required: false,
      default: () => ({
        id: null,
        username: '',
        roles: [],
        userInfos: {
          firstName: '',
          lastName: '',
          emails: [],
          phoneNumbers: [],
        },
        accountType: '',
        siteUrl: window.location.protocol + '//' + window.location.host
      }),
    },
    userRecordMethod: {
      type: Function,
      required: false,
      default: () => Promise.resolve(),
    },
    recordText: {
      type: String,
      required: false,
      default: () => 'Enregistrer les modifications'
    },
    cancelText: {
      type: String,
      required: false,
      default: () => 'Annuler'
    },
    showPasswordFields: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    showRolesSelection: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    usernameEditable: {
      required: false,
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    userCopy: {},
    userRecordInProgress: false,
    rolesList: {
      admin: 'Administrateur'
    },
  }),
  watch: {
    user: {
      deep: true,
      immediate: true,
      handler() {
        this.copyUser()
      }
    },
  },
  methods: {
    copyUser() {
      this.userCopy = Object.assign({}, JSON.parse(JSON.stringify(this.user)))
    },
    updateUserRole(role) {
      if (this.userCopy.roles.includes(role)) {
        this.userCopy.roles.splice(this.userCopy.roles.indexOf(role), 1);
      } else {
        this.userCopy.roles.push(role)
      }
    },
    userRecord() {
      this.userRecordInProgress = true
      this
        .userRecordMethod(this.userCopy)
        .then(() => {
          this.copyUser()
        })
        .finally(() => {
          this.userRecordInProgress = false
        })
    }
  },
  computed: {
    userHasDataChanged() {
      return JSON.stringify(this.user) !== JSON.stringify(this.userCopy)
    },

    userInfo() {
      return this.$route.meta
    }
  },
  created() {
    this.copyUser()
  },
}
</script>

<style scoped></style>
